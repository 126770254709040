<template>
  <div class="wizard-container rows">
    <OfferForm
      :offer="offer"
      @redirectToOffers="$router.push('/sadmin/offers')"
      :editOfferFlag="true"
    />
  </div>
</template>

<script>
import OfferForm from '@/components/SAdmin/OfferForm.vue'

export default {
  components: {
    OfferForm
  },
  data () {
    return {
      offer: {
        name: '',
        price: '',
        credits: '',
        description: ''
      },
      submittingForm: false
    }
  },
  name: 'CreateOffer',
  methods: {
    getOffer () {
      this.$store.dispatch('getOffer', this.$route.params.offerId).then((response) => {
        this.offer = response.response.body
      }, (response) => {
        this.busy = false
      })
    }
  },
  mounted () {
    this.getOffer()
  }
}
</script>
<style scoped>
.wizard-container {
  width: 70%;
  margin: auto;
  border: solid 2px rgb(130, 222, 109);
  padding: 1%;
  border-radius: 20px;
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url('/img/Create Pickl/selectproductDropdown_icon.png');
  background-color: white;
}
.answer-input {
  height: 30px;
  border-radius: 10px;
  margin: 0;
  width: 90%;
}
.add-more-ans {
  font-size: 15px;
  text-decoration: none;
  display: block;
  margin-left: 3%;
}
.single-quesion-box {
  border: solid 1px rgb(130, 222, 109);
  padding: 1%;
  border-radius: 20px;
  margin: 10px;
}
.add-question-button {
  border: solid 1px rgb(130, 222, 109);
  color: black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  display: block;
  width: 20%;
  text-align: center;
  padding: 1%;
  border-radius: 10px;
}
a {
  color: #782b54;
}
.delete-question-link {
  font-size: 16px;
  margin-top: 40px;
}
.answer-container {
  margin-left: 2%;
  margin: 1%;
}
.remove-answer-button {
  background: #782b54;
  height: 35px;
  border: none;
  border-radius: 150px;
  color: white;
  padding: 3px 10px 0px 10px;
}
.localstorage-existing-data-notice {
  text-align: center;
  display: block;
  color: black;
  font-size: 16px;
  margin-bottom: 10px;
  transition: opacity cubic-bezier(0, 0.52, 1, 1) 1s;
}
.progress-bar-container {
  width: 60%;
  margin: auto;
}
@media only screen and (max-width: 1024px) {
  .wizard-container {
    width: 100%;
    margin: auto;
    border: solid 2px rgb(130, 222, 109);
    padding: 1%;
    border-radius: 20px;
  }
}
</style>
